import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Col, Container, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import PageHeaderBlock from "../../components/PageHeaderBlock";
import LastProjects from "../../components/portfolio/LastProjects";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function RedizaynOknaVhodaInternetMagazina() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t('portfolioRedizaynOknaVhodaInternetMagazina')} description={t('portfolioRedizaynOknaVhodaInternetMagazina')} />
            <PageHeaderBlock h1="portfolioRedizaynOknaVhodaInternetMagazina" />
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="portfolioPageWord" firstChildUrl={'/portfolio'} secondChild="portfolioRedizaynOknaVhodaInternetMagazina" />
                <Row className="my-3 my-xl-4 my-xxl-5 pb-xl-3 pb-xxl-4">
                    <Col md={7} xxl={9}>
                        <Row>
                            <Col xxl={7}>
                                <StaticImage
                                    src="../../images/portfolio/RedizaynOknaVhodaInternetMagazina.png"
                                    width={1280}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('portfolioRedizaynOknaVhodaInternetMagazina')}
                                    className="portfolio__img w-100 mb-3 mb-xxl-4"
                                    placeholder="blurred"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5} xxl={3} className="blog-post ps-md-3 ps-xxl-4">
                        <article className="portfolio__text position-sticky">
                            <h2>{t('portfolioDescription')}</h2>
                            <article dangerouslySetInnerHTML={{__html: t('portfolioRedizaynOknaVhodaInternetMagazinaText')}} />
                        </article>
                    </Col>
                </Row>
                <LastProjects />
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;